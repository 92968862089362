<div class="container mx-auto h-full flex flex-col text-white relative">
	<div class="h-full flex flex-col justify-center items-center">
		<h1 *ngIf="slotable.title" class="text-white font-700 text-42 md:text-48 leading-111">{{ slotable.title }}</h1>
		<span *ngIf="slotable.description"
			  class="font-400 text-20 leading-120"
			  [innerHTML]="slotable.description"></span>
	</div>
	<div class="absolute bottom-10 left-0 right-0 font-400 w-full flex justify-between text-white text-16 md:text-24">
		<span class="my-auto">{{ today | date : 'hh:mm a' }}</span>
<!--		<div *ngIf="!semaphores.loadingWeather"-->
<!--			 class="flex items-center">-->
<!--			<span class="mr-6 w-7 h-7 md:w-10 md:h-10 flex justify-center items-center">-->
<!--				<fa-icon [icon]="icon" class="w-full h-full text-white"></fa-icon>-->
<!--			</span>-->
<!--			<span class="h-15 border-l-4 pl-6 border-han">{{ weather.current.temp | number : '1.0-0' }}°C</span>-->
<!--		</div>-->
<!--		<div *ngIf="semaphores.loadingWeather"-->
<!--			 class="flex items-center">-->
<!--			<app-loader></app-loader>-->
<!--		</div>-->

	</div>

</div>
