import { Component, Inject, LOCALE_ID, OnInit, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NavigationEnd, NavigationStart, ResolveStart, Router } from '@angular/router';

import { GlobalService } from './Library/services/global.service';
import { ServerResponseService } from './Library/server-response.service';
import { LocaleService } from './Library/services/locale.service';
import { SeoService } from './Library/services/seo/seo.service';
// import { WeatherService } from './@modules/weather/services/weather.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    title = 'base-website';
    isBackButtonClicked: boolean = false;

    constructor(
        // private weatherService: WeatherService,
        private globalService: GlobalService,
        private seoService: SeoService,
        private router: Router,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document,
        private srs: ServerResponseService,
        private localeService: LocaleService
    ) {

        globalService.is404.subscribe(is404 => {
            if (is404) {
                srs.setNotFound();
            }
        });

        globalService.isRedirect.subscribe(redirectObject => {
            if (redirectObject) {
                let url = this.localeService.getRouteByElements(redirectObject.redirectUrl);
                if (this.localeService.getLocale() === 'en') {
                    url = '/en' + url;
                }
                srs.redirect(url, redirectObject.statusCode);
            }
        });

        router.events.subscribe((event) => {
            if (event instanceof ResolveStart) {
                if (globalService.is404.value === true) {
                    globalService.is404.next(false);
                }

                if (globalService.isRedirect.value) {
                    globalService.isRedirect.next(false);
                }
            }

            if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
                this.isBackButtonClicked = true;
            } else if (event instanceof NavigationStart) {
                this.isBackButtonClicked = false;
            }

            if (event instanceof NavigationEnd && !this.isBackButtonClicked) {
                if (isPlatformBrowser(this.platformId)) {
                    // window.scrollTo(0, 0); // ! this triggers on filters change
                }
            }

            if (event instanceof NavigationStart) {
                if (isPlatformBrowser(this.platformId)) {
                    this.document.body.classList.remove('overflow-hidden');
                }
            }
        });
    }

    ngOnInit(): void {
        this.seoService.init();
        // this.weatherService.getWeather().subscribe();

        if (isPlatformBrowser(this.platformId)) {
            if (this.locale === 'en') {
                document.addEventListener('userway:init_completed', (event: any) => {
                    const instance = event.detail.userWayInstance;
                    instance.changeWidgetLanguage('en');
                });
            }
        }
    }
}
