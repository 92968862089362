<section class="w-full max-w-max h-auto sm:h-[390px] mx-auto relative overflow-hidden">

	<div class="w-full aspect-[3/2] sm:aspect-auto relative sm:absolute sm:inset-0">
		<div *ngIf="contentModule.image && !contentModule.video_url" class="w-full h-full relative">
			<img appImgFallback="assets/img/default/img_default_1920x1080.jpg"
				 srcset="{{ contentModule.image.i_375x250 }} 375w, {{ contentModule.image.i_1920x1080 }} 1920w"
				 sizes="(max-width: 768px) 375px, (min-width: 768px) 1920px"
				 [src]="contentModule.image.i_1920x1080"
				 class="object-center object-cover w-full h-full"
				 alt="Digital Nomads">
		</div>

		<div class="absolute inset-0 bg-gradient-to-l from-[#00000066] w-full h-full z-10"></div>

		<div *ngIf="contentModule.video_url" class="w-full h-full relative">
			<video
					*ngIf="contentModule.video_url"
					#videoPlayer
					class="flex m-none absolute inset-0 w-full h-full object-cover object-center"
					loop playsinline="" preload="none" [muted]="true" [autoplay]="true"
					data-src="{{ contentModule.video_url }}">
				<source data-src="{{ contentModule.video_url }}" type="video/mp4">
			</video>
		</div>

		<div *ngIf="!contentModule.image && !contentModule.video_url"
			 class="w-full h-full relative">
			<img appImgFallback="assets/img/default/img_default_1920x1080"
				 srcset="assets/img/default/img_default_375x250.jpg 375w, assets/img/default/img_default_1920x1080.jpg 1920w"
				 sizes="(max-width: 768px) 375px, (min-width: 768px) 1920px"
				 src="assets/img/default/img_default_1920x1080.jpg"
				 class="object-center object-cover w-full h-full"
				 alt="Digital Nomads">
		</div>

		<div class="absolute z-20 bottom-4 left-0 right-0 sm:inset-0 container flex flex-row sm:flex-row-reverse items-center">
			<div class="w-8/24 sm:w-4/24">
				<div class="font-400 w-full flex justify-between text-white text-16 sm:text-24">
<!--					<div *ngIf="!semaphores.loadingWeather"-->
<!--						 class="flex items-center">-->
<!--					<span class="mr-6 w-7 h-7 sm:w-10 sm:h-10 flex justify-center items-center">-->
<!--						<fa-icon [icon]="icon" class="w-full h-full text-white"></fa-icon>-->
<!--					</span>-->
<!--						<span class="h-15 border-l-4 pl-6 border-han">{{ weather.current.temp | number : '1.0-0' }}°C</span>-->
<!--					</div>-->
<!--					<div *ngIf="semaphores.loadingWeather"-->
<!--						 class="flex items-center">-->
<!--						<app-loader></app-loader>-->
<!--					</div>-->
				</div>
			</div>
		</div>
	</div>

	<div class="w-full sm:absolute sm:inset-0 sm:z-20 sm:container">
		<div class="sm:h-full sm:w-15/24 sm:-ml-3/24 sm:flex sm:flex-row sm:items-center sm:justify-start">
			<app-slot-a30 [slotable]="contentModule.slotables[0]"></app-slot-a30>
		</div>
	</div>
</section>
