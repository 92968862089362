import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Slot } from '../../models/slot.model';
// import { Weather } from '../../../weather/models/weather.model';
// import { WeatherService } from '../../../weather/services/weather.service';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-slot-a40',
    templateUrl: './slot-a40.component.html',
    styleUrls: ['./slot-a40.component.scss']
})
export class SlotA40Component implements OnInit {

    @Input() slotable: Slot;
    today: Date = new Date();
    // weather: Weather;
    // semaphores: any = {
    //     loadingWeather: false
    // };
    icon: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId,
        // private weatherService: WeatherService
    ) {
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            setInterval(() => {
                this.today = new Date();
            }, 1);
        }
        // this.semaphores.loadingWeather = true;
        // this.weatherService.weather$.pipe(
        //     filter(weather => weather !== null)
        // ).subscribe(
        //     (weather: Weather) => {
        //         this.weather = weather;
        //         this.icon = this.weatherService.iconMap[this.weather.current.weather[0].icon];
        //         this.semaphores.loadingWeather = false;
        //     },
        //     err => {
        //         this.semaphores.loadingWeather = false;
        //     }
        // );
    }

}
