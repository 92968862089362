import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ContentModuleBaseComponent } from '../content-module-base.component';
import { ContentModule } from '../../models/content-module.model';
import LazyLoad from 'vanilla-lazyload';

@Component({
    selector: 'app-content-module-a010',
    templateUrl: './content-module-a010.component.html'
})
export class ContentModuleA010Component implements ContentModuleBaseComponent, OnInit, AfterViewInit {
    @Input() contentModule: ContentModule;

    videoId: string;
    player: any;
    apiLoaded: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
        const lazyLoadInstances = [];
        const lazyLoadEnter = (element) => {
            const lazyLoad = new LazyLoad({
                container: element
            });
            lazyLoadInstances.push(lazyLoad);
        };

        const lazyLoadVideo = new LazyLoad({
            elements_selector: '.lazy',
            unobserve_entered: true,
            callback_enter: lazyLoadEnter,
            threshold: 0
        });
    }

    ngAfterViewInit(): void {

    }


}
