<div class="container mx-auto md:w-full h-full py-8 slot-a40-wrapper text-white flex flex-col font-700">
	<h3 *ngIf="slotable.title"
		class="text-30 md:text-36 leading-122">{{ slotable.title }}</h3>
	<div *ngIf="slotable.description"
		 class="font-400 mt-2 text-16 md:text-18 leading-120"
		 [innerHTML]="slotable.description"></div>
	<a [appFlLink]="slotable.url"
	   *ngIf="slotable.url"
	   class="rounded-18 bg-white p-4 text-16 leading-120 mt-8 w-full text-center self-start text-gray-black md:w-auto hover:bg-black hover:text-white transition-colors duration-500 ease-in-out">
		{{ slotable.url_text ? slotable.url_text : 'Learn More' }}
	</a>
	<div class="mt-12 md:mt-16 flex justify-between items-center">
		<span class="font-400 text-24 leading-120">{{ today | date : 'hh:mm a' }}</span>
<!--		<div *ngIf="!semaphores.loadingWeather"-->
<!--			 class="flex items-center">-->
<!--			<span class="mr-4 md:mr-6 w-7 h-7 md:w-10 md:h-10 flex justify-center items-center">-->
<!--				<fa-icon [icon]="icon" class="w-full h-full text-white"></fa-icon>-->
<!--			</span>-->
<!--			<span class="h-12 border-l-4 pl-4 md:pl-6 border-white text-30 leading-120 border-opacity-50 font-400">-->
<!--				{{ weather.current.temp | number : '1.0-0' }}°C-->
<!--			</span>-->
<!--		</div>-->

	</div>
</div>
