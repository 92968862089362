export const environment: any = {
  production: true,
  staging: false,
  apiUrl: 'https://digitalnomads-api.gov.me/v1',
  baseApiUrl: 'https://digitalnomads-api.gov.me',
  websiteUrl: 'https://digitalnomads.gov.me',
  mediaUrl: 'https://storage.medialib.dev/media/',
  recaptchaSiteKey: '6Lf-ak0jAAAAAKuAXgF0cqxcOZLQfaGzCWxNDcbF',
  mailChimpEndpoint: 'https://fleka.us14.list-manage.com/subscribe/post-json?u=900bc09fcff279240663fd3f7&amp;id=bf542b4186'
};
